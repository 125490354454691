<template>
    <v-dialog
        v-model="dialog"
        max-width="800"
        scrollable
        persistent
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title class="text-h5">
                <span>{{ title }} Branch</span>
                <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="branch.name"
                                :error="$v.branch.name.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Name <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.branch.name.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <v-select
                                :items="types"
                                item-text="text"
                                item-value="value"
                                v-model="branch.type"
                                :error="$v.branch.type.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Type <span class="text-danger">*</span>
                                </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.branch.type.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.type" >* {{ errors.type[0] }}</span>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                :items="countries"
                                item-text="title"
                                item-value="id"
                                v-model="branch.country_id"
                                :error="$v.branch.country_id.$error"
                                outlined
                                @change="handleCountryChange"
                                dense
                            >
                                <template v-slot:label>
                                Country <span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.branch.country_id.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.country_id" >* {{ errors.country_id[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-select
                                :items="states"
                                item-text="name"
                                item-value="id"
                                @change="handleStateChange"
                                v-model="branch.state_id"
                                :error="$v.branch.state_id.$error"
                                outlined
                                :loading="isStateLoading"
                                dense
                            >
                                <template v-slot:label>
                                State
                                </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.branch.state_id.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.state_id" >* {{ errors.state_id[0] }}</span>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <v-select
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                v-model="branch.city_id"
                                :error="$v.branch.city_id.$error"
                                outlined
                                dense
                                :loading="isCityLoading"
                            >
                                <template v-slot:label>
                                City
                                </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.branch.city_id.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.city_id" >* {{ errors.city_id[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="branch.country_code"
                                :error="$v.branch.country_code.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Country Code
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.branch.country_code.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.country_code" >* {{ errors.country_code[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="branch.phone_no"
                                :error="$v.branch.phone_no.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Phone
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.branch.phone_no.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.phone_no" >* {{ errors.phone_no[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="branch.email"
                                :error="$v.branch.email.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Email <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.branch.email.$error">Invalid email</span>
                            <span class="text-danger" v-if="errors.email" >* {{ errors.email[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="branch.address_line_1"
                                :error="$v.branch.address_line_1.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Address Line 1 <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.branch.address_line_1.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.address_line_1" >* {{ errors.address_line_1[0] }}</span>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="branch.address_line_2"
                                :error="$v.branch.address_line_2.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Address Line 2
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.branch.address_line_2.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.address_line_2" >* {{ errors.address_line_2[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="branch.postal_code"
                                :error="$v.branch.postal_code.$error"
                                outlined
                                type="number"
                                dense
                            >
                                <template v-slot:label>
                                Postal Code
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.branch.postal_code.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.postal_code" >* {{ errors.postal_code[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="branch.google_map"
                                :error="$v.branch.google_map.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Google Map
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.branch.google_map.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.google_map" >* {{ errors.google_map[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="branch.google_map_street"
                                :error="$v.branch.google_map_street.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Google Map Street
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.branch.google_map_street.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.google_map_street" >* {{ errors.google_map_street[0] }}</span>
                        </v-col>

                        <!-- type here -->

                    </v-row>
                </v-container>
            </v-card-text>
            
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="closeDialog"
                >
                Cancel
                </v-btn>

                <v-btn
                    v-if="checkIsAccessible('branch', 'create') || checkIsAccessible('branch', 'edit')"
                    class="btn btn-primary"
                    @click="createOrUpdate()"
                    :loading="loading"
                >
                  Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import BranchService from "@/services/branch/BranchService";
import StateService from "@/services/country/state/StateService";
import CityService from "@/services/country/city/CityService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import AcademicProgramService from "@/services/academic/program/AcademicProgramService";
import CountryService from "@/services/country/CountryService";

const branch = new BranchService();
const institution = new InstitutionService();
const country = new CountryService();
const academicProgram = new AcademicProgramService();
const state = new StateService();
const city = new CityService();

export default {
  name: "CreateAndUpdate",
  validations: {
    branch: {
        name: { required },
        country_id: { required },
        state_id: {},
        city_id: {},
        country_code: {},
        type: {required},
        phone_no: {},
        email: {required,email},
        address_line_1: {required},
        address_line_2: {},
        postal_code: {},
        google_map: {},
        google_map_street: {},
    }
  },
  data() {
    return{
      loading: false,
      isStateLoading: false,
      isCityLoading: false,

      dialog: false,
      edit: false,
      title: '',
      errors: [],
      scores: [],
      types: [
          {
            text: 'Main',
            value: 'main'  
          },
          {
            text: 'Branch',
            value: 'branch'
          }
      ],
      branch: {
        name: '',
        country_id: '',
        state_id: '',
        city_id: '',
        country_code: '',
        phone_no: '',
        email: '',
        address_line_1: '',
        address_line_2: '',
        postal_code: '',
        google_map: '',
        type: '',
        google_map_street: '',
      },
      countries: [],
      states: [],
      cities: []
    }
  },

  methods:{
    handleCountryChange(){
        this.isStateLoading = true;
        this.getStatesByCountryId(this.branch.country_id);
        this.isStateLoading = false;
        this.branch.city_id = '';
    },
    handleStateChange(){
        this.isCityLoading = true;
        this.getCitiesByStateId(this.branch.state_id);
        this.isCityLoading = false;
    },
    getAllCountries(){
        country
        .getAllCountry()
        .then((response) => {
            this.countries = response.data.data;
        })
        .catch((err) => {

        })
        .finally(() => {

        });
    },
    getStatesByCountryId(countryId){
        state
        .all(countryId)
        .then((response) => {
            this.states = response.data.data;
        })
        .catch((err) => {

        })
        .finally(() => {

        });
    },
    getCitiesByStateId(stateId){
        city
        .all(stateId)
        .then((response) => {
            this.cities = response.data.data;
        })
        .catch((err) => {

        })
        .finally(() => {

        });
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    createBranch() {
      this.title = 'Add';
      this.dialog = true;
      this.getAllCountries();
      this.edit = false;
      this.resetForm();
    },
    editBranch(branch) {
      this.title = 'Edit';
      this.dialog = true;
      this.edit = true;
      this.getAllCountries();
      this.branch = branch;
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
        branch
        .create(this.branch)
        .then(response => {
        this.$snotify.success("Branch Created successfully");
        this.$emit('refresh');
        this.resetForm();
        this.closeDialog();

        this.loading = false;
        })
        .catch((error) => {
        this.loading = false;
        this.errors = error.errors;
        this.$snotify.error("Oops something went wrong");
        });
    },

    update: function () {
        branch
        .update(this.branch.id, this.branch)
        .then((response) => {
        this.$snotify.success("Branch Updated successfully");
          this.$emit('refresh');
        this.resetForm();
        this.closeDialog();

        this.loading = false;
        })
        .catch((error) => {
        this.loading = false;
        this.errors = error.errors;
        this.$snotify.error("Oops something went wrong");
        });
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.branch = {
        name: '',
        country_id: '',
        state_id: '',
        city_id: '',
        country_code: '',
        phone_no: '',
        email: '',
        address_line_1: '',
        address_line_2: '',
        postal_code: '',
        google_map: '',
        google_map_street: '',
      };
    },
  },
}
</script>