<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Branch</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Branch
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('branch', 'create')"
                    @click="createBranch()"
                    class="mt-1 btn btn-primary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Branch
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field
                            label="Name"
                            v-model="search.name"
                            outlined
                            dense  v-on:keyup.enter="searchBranch" clearable
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                        <v-text-field
                            label="Email"
                            v-model="search.email"
                            outlined
                            dense  v-on:keyup.enter="searchBranch" clearable
                        ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="6" md="2">
                        <v-btn
                            :loading="isLoading"
                            @click.prevent="searchBranch"
                            class="mt-1 btn btn-primary"
                        >
                            <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                            Search
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <div class="table-responsive" style="table-layout: fixed">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="isLoading">
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="isLoading"
                    type="table-row-divider@25">
                </v-skeleton-loader>
                
                <table class="table">
                    <thead>
                    <tr class="px-3">
                        <th><strong>Name</strong></th>
                        <th><strong>Phone Number</strong></th>
                        <th><strong>Type</strong></th>
                        <th><strong>Country</strong></th>
                        <th><strong>Email</strong></th>
                        <th class="pr-3 text-center"><strong>Action</strong></th>
                    </tr>
                    </thead>
                    <tbody>
                        <template>
                            <tr v-for="branch in branches" :key="branch.id" >
                                <td>
                                    <a @click="editBranch(branch)" 
                                        class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                    >
                                        {{ branch.name }}
                                    </a>
                                </td>
                                <td class="text-left font-weight-bolder font-size-lg">
                                    {{ branch.phone_no ? branch.phone_no : 'N/A' }}
                                </td>
                                <td class="text-left font-weight-bolder font-size-lg">
                                    {{ branch.type_text ? branch.type_text : 'N/A' }}
                                </td>
                                <td class="text-left font-weight-bolder font-size-lg">
                                    <div>{{ branch.country_name ? branch.country_name : 'N/A' }}</div>
                                    <div> <strong> State - </strong> {{ branch.state_name ? branch.state_name : 'N/A' }}</div>
                                    <div> <strong> City - </strong> {{ branch.city_name ? branch.city_name : 'N/A' }}</div>
                                </td>
                                <td class="text-primary text-left font-weight-bolder font-size-lg">
                                    {{ branch.email ? branch.email : 'N/A' }}
                                </td>
                                <td class="pr-0 text-left">
                                    <template>
                                        <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                        >
                                            <template v-slot:button-content>
                                                <i class="ki ki-bold-more-hor"></i>
                                            </template>
                                            <!--begin::Navigation-->
                                            <div class="navi navi-hover min-w-md-250px">
                                                <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('branch', 'edit')">
                                                <a @click="editBranch(branch)" class="navi-link">
                                                    <span class="navi-icon">
                                                        <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                                    </span>
                                                    <span class="navi-text">Edit</span>
                                                </a>
                                                </b-dropdown-text>
                                                <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('branch', 'delete')">
                                                    <a class="navi-link" @click.prevent="deleteBranch(branch.id)">
                                                        <span class="navi-icon">
                                                            <v-icon color="red darken-2">fas fa-trash</v-icon>
                                                        </span>
                                                        <span class="navi-text">Delete</span>
                                                    </a>
                                                </b-dropdown-text>
                                            </div>
                                        </b-dropdown>
                                    </template>
                                </td>
                            </tr>
                            
                            <tr v-if="branches.length == 0" >
                                <td colspan="5" class="text-center"><strong>No Data Found</strong></td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                
                <b-pagination
                    v-if="branches.length > 0"
                    class="pull-right mt-7"  
                    :disabled="isLoading"
                    @input="getAllBranches"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllBranches"></create-and-update>
    </div>  
  </v-app>
</template>
<script>
import CreateAndUpdate from "@/view/pages/view/branch/CreateAndUpdate";
import BranchService from "@/services/branch/BranchService";

const branch = new BranchService();

export default {
    name: "Index",
    components: {CreateAndUpdate},
    data() {
        return{
        isLoading: false,
        total: null,
        perPage: null,
        page: null,
        branches: [],
        search:{
            name: '',
            email: '',
        },
        }
    },
    mounted(){
        this.getAllBranches();
    },
    methods: {
        getAllBranches(){
            this.isLoading = true;
            branch
            .paginate(this.search, this.page)
            .then((response) => {
                this.branches = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
                this.isLoading = false;
            })
            .catch((err) => {

            });
        },
        searchBranch(){
            this.getAllBranches();
        },
        createBranch(){
            this.$refs['create-and-update'].createBranch();
        },
        editBranch(branch){
            this.$refs['create-and-update'].editBranch(branch);
        },
        deleteBranch(id){
          this.$confirm({
            message: `Are you sure? `,
            button: {
                no: "No",
                yes: "Yes",
            },
            callback: (confirm) => {
                if (confirm) {
                    branch
                    .delete(id)
                    .then((response) => {
                        this.getAllBranches()
                        this.$snotify.success("Branch Deleted Successfully ");
                    })
                    .catch((err) => {
                        this.$snotify.error("Oops something went wrong");
                    });
                }
            }
        });
        }
    }
}
</script>